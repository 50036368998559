import {mount} from 'svelte';

import {mapStore} from '@/stores/mapStore';

import I18n from '@/modules/translations';
import * as Utils from '@/modules/utils';
import {videoLazyLoader} from '@/modules/videoLazyLoader';

import Notification from '@/components/layout/notification';

import HomeSearch from '@/components/home/HomeSearch.svelte';
import HomeComputedDistance from '@/components/home/HomeComputedDistance.svelte';
import RidesVisited from '@/components/ride/list/RidesVisited.svelte';

import AnalyticsService from '../../assets/javascripts/modules/analyticsService';


class HomeManager {
    _components = {
        $searchForm: document.getElementById('home-search'),
        $map: document.getElementById('home-map-container'),
        $computedDistance: document.getElementById('home-computed-distance'),
        $computation: document.getElementById('home-computation'),
        $rideCreation: document.getElementById('home-ride-creation'),
        $ridesVisited: document.getElementById('home-rides-visited'),
        $homeAround: document.getElementById('home-around')
    };

    _buttons = {
        $mapFocus: document.getElementById('home-map-focus'),
        $mapLocateMe: document.getElementById('home-map-geolocation'),
        $insuranceMap: document.getElementById('home-insurance-map'),
        $insuranceTime: document.getElementById('home-insurance-time')
    };

    /* Public methods
    ******************** */
    // constructor() {
    // }

    initialize = () => {
        this._mountComponents();

        this._mountAsyncComponents();

        this._buttonInteractions();

        videoLazyLoader();
    };

    /* Private methods
    ******************** */
    _mountComponents = () => {
        mount(HomeSearch, {
            target: this._components.$searchForm
        });

        mount(RidesVisited, {
            target: this._components.$ridesVisited,
            props: {
                class: 'main-section mt-16',
                titleClass: 'home-title mb-6'
            }
        });

        if (this._components.$computedDistance) {
            mount(HomeComputedDistance, {
                target: this._components.$computedDistance
            });
        }
    };

    _mountAsyncComponents = () => {
        Utils.onPageReady(() => {
            import('@/components/home/HomeMap.svelte')
                .then(({default: HomeMapComponent}) => {
                    mount(HomeMapComponent, {
                        target: this._components.$map
                    });

                    // Class used for preserving space on initial load
                    this._components.$map.classList.remove('home-map-container');

                    setTimeout(() => {
                        import('@/modules/maps/buildMap');
                    }, 100);
                });

            import('@/components/home/HomeAround.svelte')
                .then(({default: HomeAround}) => {
                    mount(HomeAround, {
                        target: this._components.$homeAround
                    });
                });

            setTimeout(() => {
                import('@/components/home/HomeComputation.svelte')
                    .then((HomeComputationComponent) => {
                        mount(HomeComputationComponent.default, {
                            target: this._components.$computation
                        });
                    });
            }, 800);

            setTimeout(() => {
                import('@/components/ride/RideCreation.svelte')
                    .then((RideCreationComponent) => {
                        mount(RideCreationComponent.default, {
                            target: this._components.$rideCreation
                        });
                    });
            }, 1600);
        });
    };

    _buttonInteractions = () => {
        this._buttons.$mapFocus
            .addEventListener('click', this._focusOnMap);
        this._buttons.$insuranceMap
            .addEventListener('click', this._focusOnMap);
        this._buttons.$insuranceTime
            .addEventListener('click', this._focusOnMap);

        this._buttons.$mapLocateMe
            .addEventListener('click', this._locateMe);
    };

    _focusOnMap = (event) => {
        event.preventDefault();

        AnalyticsService.trackHomeShowMapClick();

        setTimeout(() => Utils.scrollTo('home-map'), 150);
    };

    _locateMe = (event) => {
        event.preventDefault();

        Notification.info(I18n.t('js.maps.geolocation.in_progress'));

        AnalyticsService.trackGeolocationClick('Home page');

        if (mapStore.map) {
            mapStore.map.onGeolocation(
                () => {
                    setTimeout(() => Utils.scrollTo('home-map'), 350);

                    setTimeout(() => Notification.hide(), 600);
                },
                () => {
                    setTimeout(() => Utils.scrollTo('home-map'), 350);
                });
        }
    };
}

export default new HomeManager();
